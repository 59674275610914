import { container, title } from "assets/jss/material-kit-react.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const carouselStyle = {
  section: {
    padding: "30px 0",
  },
  container,
  title: {
    ...title,
    marginTop: "25px",
    minHeight: "27px",
    textDecoration: "none",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  ...imagesStyles,
};

export default carouselStyle;
