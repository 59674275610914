/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import image1 from "assets/img/artiste/p1040635.jpg";
import image2 from "assets/img/artiste/p1040636.jpg";
import image3 from "assets/img/artiste/p1040640.jpg";
import image4 from "assets/img/artiste/p1040644.jpg";
import image5 from "assets/img/artiste/p1040646.jpg";
import image6 from "assets/img/artiste/p1040653.jpg";
import image7 from "assets/img/artiste/p1040695.jpg";
import image8 from "assets/img/artiste/p1040696.jpg";
import image9 from "assets/img/artiste/p1040705.jpg";
import image10 from "assets/img/artiste/14.jpg";
import image11 from "assets/img/artiste/1.jpg";
import image12 from "assets/img/artiste/2.jpg";
import image13 from "assets/img/artiste/3.jpg";
import image14 from "assets/img/artiste/5.jpg";
import image15 from "assets/img/artiste/9.jpg";
import imageAndreanne from "assets/img/artiste/andreanne-drapeau-artiste-coiffeur.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionArtist() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>
            Andréanne Drapeau <strong>Artiste&nbsp;coiffeur</strong>
          </h2>
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={3} md={3}>
                  <img
                    src={imageAndreanne}
                    style={{ width: "100%", marginTop: "22px" }}
                    className={
                      classes.imgRaised +
                      " " +
                      classes.imgRounded +
                      " " +
                      classes.imgFluid
                    }
                  ></img>
                </GridItem>
                <GridItem xs={12} sm={9} md={9}>
                  <div className={classes.title}>Formation</div>
                  <ul>
                    <li>
                      Diplômée en 1993 de l'école Rolande Saint-Germain en
                      coiffure.
                    </li>
                  </ul>
                  <div className={classes.title}>Perfectionnement</div>
                  <ul>
                    <li>4 congrès de perfectionnement par année.</li>
                    <li>
                      Diplômée en 2006 avec la compagnie GREAT LENGTHS
                      (extension 100% cheveux humains).
                    </li>
                    <li>
                      Cours de coloration avec la compagnie By Fama en septembre
                      2007.
                    </li>
                    <li>Cours de coloration avec la compagnie MATRIX 2018.</li>
                    <li>Artiste de plateau pour MATRIX pendant 5 ans.</li>
                    <li>4 formations avec Kevin Murphy 2019.</li>
                  </ul>
                  <div className={classes.title}>Trophées remportés</div>
                  <ul>
                    <li>
                      3ème place en coupe et mise en plis de soir, provincial en
                      2002.
                    </li>
                    <li>
                      1ère place en coupe et mise en plis de soir, provincial en
                      2003.
                    </li>
                    <li>
                      1ère place en coupe et mise en plis avant garde,
                      provincial en 2005.
                    </li>
                    <li>
                      Dans les 6 premiers coiffeurs au Canada en 2006 pour la
                      compétition Mirror Awards.
                    </li>
                  </ul>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8} md={6} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image1}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image2}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image3}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image4}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image6}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image8}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image9}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      Artiste de plateau pour MATRIX
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image11}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      3e place en coupe et mise en plis de soir, provincial 2002
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image12}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      1e place en coupe et mise en plis de soir, provincial en
                      2003
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image13}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      1e place en coupe et mise en plis avant-garde, provincial
                      en 2005
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image14}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      1e place en coupe et mise en plis avant-garde, provincial
                      en 2005
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image15}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      1e place en coupe et mise en plis avant-garde, provincial
                      en 2005
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image10}
                    alt="Prestige Coiffure"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4 className="text-shadow">
                      1e place en coupe et mise en plis avant-garde, provincial
                      en 2005
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
