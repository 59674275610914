/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import imageColorMe from "assets/img/products/color-me.jpg";
import imageKevin from "assets/img/products/kevin-murphy.jpg";
import imageByFama from "assets/img/products/by-fama.jpg";
import imageElumen from "assets/img/products/elumen.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/productsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="images">
          <div className={classes.title}>
            <h2>Produits</h2>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={11} md={11}>
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <div className={classes.space20} />
                  <a
                    href="https://kevinmurphy.com.au/"
                    target="_blank"
                    alt="Kevin Murphy"
                  >
                    <img
                      src={imageKevin}
                      alt="Kevin Murphy"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </a>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <h3>
                    {" "}
                    <a
                      href="https://kevinmurphy.com.au/"
                      target="_blank"
                      alt="Kevin Murphy"
                    >
                      Kevin Murphy
                    </a>
                  </h3>
                  <div>
                    Passé maître dans l'art de la coiffure, Kevin Murphy connaît
                    le pouvoir de transformation du cheveu mieux que quiconque.
                    Ses créations ont fait la couverture de tous les plus grands
                    magazines du monde et Kevin a dirigé les équipes de coiffure
                    des maisons de mode les plus renommées. Après avoir suivi
                    une formation dans le salon Vidal Sassoon de Londres, Kevin
                    retourne en Australie pour s'imposer comme l'un des
                    coiffeurs les plus sollicités du pays. Participant
                    régulièrement aux publications du magazine australien Vogue,
                    il se trouve un créneau dans l'industrie de la coiffure en
                    devenant l'expert du « look plage ». En 2007, Kevin se voit
                    décerner le prix australien « Masters Award » lors des
                    Australian Hair Fashion Awards, pour sa contribution
                    exceptionnelle à la promotion de la coiffure australienne
                    partout dans le monde. Pour en savoir plus,{" "}
                    <a
                      href="https://www.youtube.com/user/LoveKevinMurphy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      regardez les différentes vidéos de Kevin Murphy
                    </a>
                    .
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.space50} />
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <div className={classes.space20} />
                  <a
                    href="https://kevinmurphy.com.au/color-me"
                    target="_blank"
                    alt="COLOR.ME by Kevin Murphy"
                  >
                    <img
                      src={imageColorMe}
                      alt="COLOR.ME by Kevin Murphy"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </a>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <h3>
                    {" "}
                    <a
                      href="https://kevinmurphy.com.au/color-me"
                      target="_blank"
                      alt="COLOR.ME by Kevin Murphy"
                    >
                      COLOR.ME by Kevin Murphy
                    </a>
                  </h3>
                  <div>
                    COLOR.ME donne vie à la passion et la philosophie de Kevin
                    en conjuguant coiffure de mode, haute couture et coloration
                    au service d'une gamme de couleurs luxueuses, haute
                    performance et multi-tons. Conçue pour offrir une couleur
                    naturelle et une efficacité à toute épreuve, la gamme
                    COLOR.ME a été pensée pour les coloristes et formulée à
                    partir d'ingrédients naturels qui hydratent et revitalisent
                    les cheveux tout en leur apportant brillance et couleur, le
                    tout sans nuire à l'environnement. De plus, les produits
                    COLOR.ME n'ont pas été testés sur des animaux et sont sans
                    ammoniaque (pas d'odeur désagréable) et sans PPD (ce qui
                    réduit les risques d'irritation et de réaction allergique).
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.space50} />
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <div className={classes.space20} />
                  <a
                    href="https://www.professionalbyfama.com"
                    target="_blank"
                    alt="BY FAMA"
                  >
                    <img
                      src={imageByFama}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </a>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <h3>
                    {" "}
                    <a
                      href="https://www.professionalbyfama.com"
                      target="_blank"
                      alt="BY FAMA"
                    >
                      BY FAMA
                    </a>
                  </h3>
                  <div>
                    Les produits PROFESSIONAL BY FAMA pour la coloration. Des
                    produits avancés et performants au service des coloristes.
                    Un système intégré de couleur et de blanchiment pour créer
                    des résultats exclusifs et personnalisés. Un arc-en-ciel de
                    200 nuances.
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.space50} />
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <div className={classes.space20} />
                  <a
                    href="https://www.goldwell.com/en-us/products/color/permanent/elumen"
                    target="_blank"
                    alt="GOLDWELL ELUMEN"
                  >
                    <img
                      src={imageElumen}
                      alt="GOLDWELL ELUMEN"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </a>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <h3>
                    {" "}
                    <a
                      href="https://www.goldwell.com/en-us/products/color/permanent/elumen"
                      target="_blank"
                      alt="GOLDWELL ELUMEN"
                    >
                      GOLDWELL ELUMEN
                    </a>
                  </h3>
                  <div>
                    Produit de coloration de haute performance et sans oxydant.
                    Brillance jamais vue avant. Coloration permanente et extra
                    longue durée et reflet remarquablement luisant,
                    conditionneur doux.
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
