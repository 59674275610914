import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import SectionCoords from "./Sections/SectionCoords.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionArtist from "./Sections/SectionArtist.js";
import SectionHours from "./Sections/SectionHours.js";
import SectionPrestige from "./Sections/SectionPrestige.js";
import SectionProducts from "./Sections/SectionProducts.js";
import SectionAbout from "./Sections/SectionAbout.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <a href="#top" name="top" id="top" style={{ height: "0px" }}></a>
      <Header
        brand="Prestige&nbsp;Coiffure"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/bg-prestige-coiffure.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={7}>
              <div className={classes.brand}>
                <h2 className={classes.beloeil}>À&nbsp;Beloeil</h2>
                <h1 className={classes.title}>Prestige Coiffure</h1>
                <h3 className={classes.subtitle}>Andréanne&nbsp;Drapeau</h3>
                <div className={classes.phone1}>Répondeur en tout temps</div>
                <div className={classes.phone2}>450 464-8834</div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionCoords />
        <SectionHours />
        <SectionPrestige />
        <SectionArtist />
        <SectionProducts />
        <SectionAbout />
      </div>
      <Footer />
    </div>
  );
}
