import { container, title } from "assets/jss/material-kit-react.js";

const prestigeStyle = {
  section: {
    padding: "30px 0",
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "-10px",
  },
};

export default prestigeStyle;
