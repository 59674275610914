/* eslint-disable prettier/prettier */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import thumb1 from "assets/img/about/thumb-prestige-coiffure-1.jpg";
import thumb2 from "assets/img/about/thumb-prestige-coiffure-2.jpg";
import thumb3 from "assets/img/about/thumb-prestige-coiffure-3.jpg";
import thumb4 from "assets/img/about/thumb-prestige-coiffure-4.jpg";
import thumb5 from "assets/img/about/thumb-prestige-coiffure-5.jpg";
import thumb6 from "assets/img/about/thumb-prestige-coiffure-6.jpg";

import image1 from "assets/img/about/prestige-coiffure-1.jpg";
import image2 from "assets/img/about/prestige-coiffure-2.jpg";
import image3 from "assets/img/about/prestige-coiffure-3.jpg";
import image4 from "assets/img/about/prestige-coiffure-4.jpg";
import image5 from "assets/img/about/prestige-coiffure-5.jpg";
import image6 from "assets/img/about/prestige-coiffure-6.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/aboutStyle.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SectionTypography() {
  const classes = useStyles();
  const [classicModal1, setClassicModal1] = React.useState(false);
  const [classicModal2, setClassicModal2] = React.useState(false);
  const [classicModal3, setClassicModal3] = React.useState(false);
  const [classicModal4, setClassicModal4] = React.useState(false);
  const [classicModal5, setClassicModal5] = React.useState(false);
  const [classicModal6, setClassicModal6] = React.useState(false);
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="images">
          <div className={classes.title}>
            <h2>On parle de nous</h2>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <GridContainer>
                {/* /////////////////////////////////////////////////// */}
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal1(true)}
                  >
                    <img
                      src={thumb1}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal1}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal1(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal1(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image1}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
                {/* /////////////////////////////////////////////////// */}
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal2(true)}
                  >
                    <img
                      src={thumb2}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal2}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal2(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal2(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image2}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal3(true)}
                  >
                    <img
                      src={thumb3}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal3}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal3(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal3(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image3}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal4(true)}
                  >
                    <img
                      src={thumb4}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal4}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal4(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal4(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image4}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal5(true)}
                  >
                    <img
                      src={thumb5}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal5}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal5(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal5(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image5}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <Button
                    color="primary"
                    className={classes.buttonImage}
                    block
                    onClick={() => setClassicModal6(true)}
                  >
                    <img
                      src={thumb6}
                      alt="BY FAMA"
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center,
                      paper: classes.modal,
                    }}
                    maxWidth={'md'}
                    open={classicModal6}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal6(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal6(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <img
                        src={image6}
                        alt="BY FAMA"
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8} className={classes.marginAuto}>
                  <iframe
                    src="https://player.vimeo.com/video/171119839?title=0&byline=0&portrait=0"
                    width="100%"
                    height="320"
                    style={{ border: "0px", margin: "10px 0 0" }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
