/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import image1 from "assets/img/prestige/salon-1.jpg";
import image2 from "assets/img/prestige/salon-2.jpg";
import image3 from "assets/img/prestige/salon-3.jpg";
import image4 from "assets/img/prestige/salon-4.jpg";
import image5 from "assets/img/prestige/prestige-2.jpg";
import image6 from "assets/img/prestige/prestige-3.jpg";
import image7 from "assets/img/prestige/prestige-4.jpg";
import image8 from "assets/img/prestige/prestige-5.jpg";
import image9 from "assets/img/prestige/prestige-7.jpg";
import image10 from "assets/img/prestige/prestige-8.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/prestigeStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPrestige() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>
            Prestige&nbsp;Coiffure <strong>Le&nbsp;salon</strong>
          </h2>
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
                  <Card carousel>
                    <Carousel {...settings}>
                      <div>
                        <img
                          src={image1}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                        <div className="slick-caption">
                          <h4 className="text-shadow">
                            Le salon Prestige Coiffure
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src={image2}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                        <div className="slick-caption">
                          <h4 className="text-shadow">
                            Le salon Prestige Coiffure
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src={image3}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                        <div className="slick-caption">
                          <h4 className="text-shadow">
                            Le salon Prestige Coiffure
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src={image4}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                        <div className="slick-caption">
                          <h4 className="text-shadow">
                            Le salon Prestige Coiffure
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img
                          src={image5}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                      <div>
                        <img
                          src={image6}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                      <div>
                        <img
                          src={image9}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                      <div>
                        <img
                          src={image10}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                      <div>
                        <img
                          src={image7}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                      <div>
                        <img
                          src={image8}
                          alt="Prestige Coiffure"
                          className="slick-image"
                        />
                      </div>
                    </Carousel>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ul>
                    <li>
                      Entrez dans un monde de pensées créatives et sans
                      limites...
                    </li>
                    <li>
                      Dans un salon épuré n'utilisant que des produits
                      écologiques et non testés sur des animaux, la créative et
                      talentueuse styliste Andréanne Drapeau, vous accueille
                      avec toute sa passion pour la coiffure.
                    </li>
                    <li>
                      Andréanne a remporté de nombreux prix et trophées au fil
                      des ans, dans le domaine de la coupe et coiffure
                      d’avant-garde.
                    </li>
                    <li>
                      Elle a obtenu une mention d’honneur d’un magazine célèbre
                      à titre d'une des meilleures artistes coiffeur au Canada.
                    </li>
                    <li>
                      Elle continue de se perfectionner année après année afin
                      de demeurer à la fine pointe des dernières tendances et
                      techniques.
                    </li>
                    <li>
                      Prenez rendez-vous avec Andréanne, qui vous charmera par
                      son dynamisme et son souci du détail.
                    </li>
                  </ul>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
