/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Heures d'ouverture</h2>
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11} md={11}>
            <div>Mercredi: 9h00 à 21h00</div>
            <div>Jeudi: 9h00 à 21h00</div>
            <div>Vendredi: 9h00 à 17h00</div>
            <div>Samedi: 9h00 à 14h00</div>
            <div className={classes.space14}></div>
            <div>
              Fermé les samedis en été du 1<sup>er</sup> juillet à la fête du
              travail
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11} md={11}>
            <div className={classes.title}>Politique de rendez-vous</div>
            <div>
              Nous vous suggérons de prendre rendez-vous au moins une semaine à
              l'avance.
            </div>
            <div>
              Par respect pour notre clientèle, toute annulation doit être
              effectuée au moins 48 heures à l'avance.
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
