import { container, title } from "assets/jss/material-kit-react.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
import { grayColor } from "assets/jss/material-kit-react.js";

const aboutStyle = {
  section: {
    padding: "30px 0",
  },
  container: {
    ...container,
    marginBottom: "30px",
  },
  title: {
    ...title,
    marginTop: "60px",
    marginBottom: "30px",
    minHeight: "27px",
    textDecoration: "none",
  },
  ...modalStyle,
  modalBody: {
    paddingTop: "8px",
    paddingRight: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "20px",
    paddingRight: "10px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  space20: {
    height: "16px",
    display: "block",
  },
  space50: {
    height: "80px",
    display: "block",
  },
  buttonImage: {
    ...buttonStyle,
    padding: "5px 5px",
    backgroundColor: grayColor,
    primary: {
      backgroundColor: grayColor,
    },
  },
  ...imagesStyles,
};

export default aboutStyle;
