/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/coordsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCoords() {
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Coordonnées</h2>
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11} md={11}>
            <div className={classes.title}>
              Prestige Coiffure Andréanne Drapeau
            </div>
            <div>1372 Rue Régis-Phaneuf</div>
            <div>Beloeil, QC, Canada</div>
            <div>J3G 0P9</div>
            <div>450 464-8834</div>
            <div>
              Facebook{" "}
              <a
                href="https://www.facebook.com/coiffurebeloeil"
                target="_blank"
                rel="noreferrer"
              >
                facebook.com/coiffurebeloeil
              </a>
            </div>
            <div className={classes.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.1149955107962!2d-73.21766568443843!3d45.58823297910249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9aad03f9a17f5%3A0xee9310799a3f3044!2sPrestige%20Coiffure%20Andr%C3%A9anne%20Drapeau!5e0!3m2!1sfr!2sca!4v1624254321262!5m2!1sfr!2sca"
                width="100%"
                height="400"
                style={{ border: "0px", margin: "10px 0 0" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
